import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-5, 16, 15, -4],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});

    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=17;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.polygon.highlight=false;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeLogo(brd1);
    placeTitle(brd1, 'Area of a Triangle', '(Showing that area of a triangle is bh/2)');
////////////////////////////////////////////////////////////////////////////////
    var analytics = placeUndo(brd1);
    analytics.setLabel('Tap to Go to Previous Step');
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeRedo(brd1);
    erase.setLabel('Tap to Go to Next Step')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////
    var i = 0;
    var Pt0 = placePoint(brd1,  2.5, 0, 3, 'red', 'red');
    placeLabel(brd1, Pt0, 'B',-15, -15);
    var Pt1 = placePoint(brd1,  2.5, 6, 3, 'red', 'red');
    //Pt1.moveTo([0, ()=>Pt1.Y()]);
    placeLabel(brd1, Pt1, 'A', -15, 15);
    var Pt2 = placePoint(brd1,  7.5, 0, 3, 'red', 'red');
    placeLabel(brd1, Pt2, 'C', 15, -15);
    var Pt3 = placePoint(brd1, ()=>Pt2.X(), ()=>Pt1.Y(), 3, 'red', 'red');
    placeLabel(brd1, Pt3, 'D', 15, 15);
    Pt3.setAttribute({visible:()=>i>0});
    var Horz = placeDash(brd1, [()=>Pt2.X(), 0.0], [()=>Pt2.X(), ()=>Pt1.Y()], 2, 'black');
    Horz.setAttribute({visible:()=>i>0});
    var Vert =placeDash(brd1, [2.5, ()=>Pt1.Y()], [()=>Pt2.X(), ()=>Pt1.Y()], 2, 'black');
    Vert.setAttribute({visible:()=>i>0});
    var shape =placeTri(brd1, [()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt0.X(), ()=>Pt0.Y()], [()=>Pt2.X(), ()=>Pt2.Y()],'blue');
    shape.setAttribute({fillOpacity:function(){if(i==2){return 0.}else{return 0.5}}, borders:{visible:true, dash:1, strokeColor:'black', strokeWidth:2}});
    var tri2 = placeTri(brd1,[()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt2.X(), ()=>Pt2.Y()], [()=>Pt2.X(), ()=>Pt1.Y()], 'red');
    tri2.setAttribute({visible:()=>i>0, fillOpacity:function(){if(i==2){return 0.}else{return 0.5}}, borders:{visible:false, strokeColor:'black', strokeWidth:2}});
    var sq = placeSq(brd1, [()=>Pt0.X(), ()=>Pt0.Y()],[()=>Pt2.X(), ()=>Pt1.Y()], 'green');
    sq.setAttribute({visible:()=>i>1, fillOpacity:0.5, borders:{visible:false, strokeColor:'black', strokeWidth:2}});
    //placeLine(brd1, [2., -2.], [7., -2.], 4, 'grey');
  //  placeImage(brd1,'/assets/substract.svg', 2.2, -4.5, 5, 0);
    var circOne = placeImage(brd1,'/assets/fbd/circleWhite.svg',  2.0, -2.5, 0, 0);
    var circTwo = placeImage(brd1,'/assets/fbd/circleWhite.svg',  4.5, -2.5, 0, 0);
    var circThree =placeImage(brd1,'/assets/fbd/circleWhite.svg',  7.0, -2.5, 0, 0);
    var circ =placeImage(brd1,'/assets/fbd/circle.svg', 2.0, -2.5, 0, 0);
    var one =placeImage(brd1,'/assets/eggOne.svg', -1.6, 10.1, 0.8, 0);
    one.setAttribute({opacity:1.0});
    var two = placeImage(brd1,'/assets/eggTwo.svg',-1.6, 9.1, 0.8, 0);
    two.setAttribute({opacity:function(){if(i>0){return 1}else{return 0.}}});
    var three =placeImage(brd1,'/assets/eggThree.svg', -1.6, 8.1, 0.8, 0);
    three.setAttribute({opacity:function(){if(i>1){return 1}else{return 0.}}});
    one.on('down', function(){circ.moveTo([2.0, -2.5], 100); i=0;});
    two.on('down', function(){circ.moveTo([4.5, -2.5], 100); i=1;});
    three.on('down', function(){circ.moveTo([7.0, -2.5], 100); i=2});
    function movePt2(){
    Pt2.moveTo([3+Math.round(4*Math.random()), 0],100);};
    function movePt1(){
    Pt1.moveTo([0.0, 3+Math.round(4*Math.random())],100);};
    analytics.on('down', function(){
      if(i>0)
      {
      i-=1;
      }
      else
      {
        i=0;
      }
    });
    erase.on('down', function(){
      if(i<2)
      {
      i+=1;
      }
      else
      {
        i=2;
      }
    })
    var line1 = placeMiddleText(brd1, 2, 10.5, 'Area of Triangle ABC');
    line1.setAttribute({color:'blue'});
    var line2 = placeLeftText(brd1, 3, 9.5, '= Area of Triangle ACD');
    line2.setAttribute({visible:()=>i>0, color:'red'});
    var line3 = placeLeftText(brd1, 5, 8.5, ()=>'= 1/2 x Area of Rectangle ABCD = ' + '1/2 x '+'b'+' x '+'h');
    line3.setAttribute({visible:()=>i>1, color:'green'});

///////////////////////////////////////////////////////////////////////
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
